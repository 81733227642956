import React, { ChangeEvent, DragEvent, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './uploadTranslationFile.scss';

interface FileUploadProps {
    acceptType: string;
    fileUploadHandler: (files: File[], portalname: string) => void;
    enableDragAndDrop?: boolean;
    show: boolean;
    setShow: (show: boolean) => void;
    uploadMultiple: boolean;
    AcceptedFilesPlaceholder: string;
    portalMenu: any[];
}

const FileUploadModal = ({
    acceptType,
    fileUploadHandler,
    enableDragAndDrop = false,
    show,
    setShow,
    uploadMultiple,
    AcceptedFilesPlaceholder,
    portalMenu
}: FileUploadProps) => {
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [fileData, setFileData] = useState<File | null>(null);
    const [isDragOver, setIsDragOver] = useState<boolean>(false);
    const [portalSelected, setPortalSelected] = useState<string>('');
    const [dropdownChanged,setDropdownChanged]=useState<boolean>(false)

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const files = event.target.files;

        if (files && files.length > 0) {
            const file = files[0];
            if (file.type !== 'application/json') {
                setErrorMessages(['Please upload a valid JSON file.']);
                return;
            }
            setFileData(file);
            setErrorMessages([]);
        } else {
            setErrorMessages(['No file selected.']);
        }
    };

    const handleDragOver = (event: DragEvent) => {
        event.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = () => {
        setIsDragOver(false);
    };

    const handleDrop = (event: DragEvent) => {
        event.preventDefault();
        setIsDragOver(false);

        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];
            if (file.type !== 'application/json') {
                setErrorMessages(['Please upload a valid JSON file.']);
                return;
            }
            setFileData(file);
            setErrorMessages([]);
        }
    };

    const handlePortalChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setDropdownChanged(true)
        setPortalSelected(event.target.value);
        if (!event.target.value) {
            setErrorMessages(['Please select a portal.']);
        }else{
            setErrorMessages([]);
        }
    };

    const handleUpload = () => {
        if (!portalSelected) {
            setErrorMessages(['Please select a portal.']);
            return;
        }
        if (!fileData) {
            setErrorMessages(['Please upload a file.']);
            return;
        }
        fileUploadHandler([fileData], portalSelected);
        setShow(false);
    };

    return (
        <Modal className="modalLayoutWrapper" show={show} backdrop="static" size="lg" keyboard={false} centered>
            <Modal.Body>
                <div className="upload-container">
                    <button className="closeButton" onClick={() => setShow(false)}>✖</button>
                    <h4 className="mb-3 text-left">Upload Translation File</h4>

                    {/* Select Portal Dropdown */}
                    <select
                        className="form-select mb-2"
                        value={portalSelected}
                        onChange={handlePortalChange}
                    >
                        <option value="">Select the Portal</option>
                        {portalMenu?.map((portal, index) => (
                            <option key={index} value={portal}>{portal}</option>
                        ))}
                    </select>
                   

                    {/* File Upload Input */}
                    <label className="uploadImageWrap d-block">
                        <input
                            type="file"
                            accept={acceptType}
                            onChange={handleFileUpload}
                            multiple={uploadMultiple}
                            className="file-input"
                        />
                        {enableDragAndDrop ? (
                            <div
                                className={`dropzone ${isDragOver ? 'drag-over' : ''}`}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}
                            >
                                <i className="fa-solid fa-upload"></i>
                                <p>Drag & Drop file here</p>
                            </div>
                        ) : (
                            <>
                            <div className="dropzone">
                                <i className="fa-solid fa-upload"></i>
                                <p>Click to select a file</p>
                                </div>
                            </>
                        )}
                    </label>

                    {fileData && <p className="file-info">Selected file: {fileData.name}</p>}

                    {/* Display Errors */}
                    {errorMessages.length > 0 && (
                        <div className="error-messages text-danger">
                            {errorMessages.map((error, index) => (
                                <span key={index}>{error}</span>
                            ))}
                        </div>
                    )}

                    {/* Upload Button */}
                    <Button
                        className="button button-primary mt-3 button-rounded button-sm fontsize-14 fw500 px-3 mb-3 statusbutton button-min-150"
                        onClick={handleUpload}
                        
                    >
                        Upload
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default FileUploadModal;
