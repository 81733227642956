import React, { useEffect, useState } from 'react';
import { useMutation } from "react-query";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
import VerifyOtp from "./VerifyOtp";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import NotFound from './notFound';

const resetPassword = (payload: any) => {
  return axios.post(`${process.env.REACT_APP_USER_API_BASEURL}/users/reset-password`, payload);
}

export const useResetPassword = () => {
  return useMutation(resetPassword)
}


const PasswordReset = () => {
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams()
  let searchEmail = searchParams.get("email");
  const { mutate: resetPassword, data:resetPasswordData, error:resetPasswordError, reset } = useResetPassword();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passError, setPassError] = useState("")
  const [confirmPassError, setConfirmPassError] = useState("")
  const navigate = useNavigate();

  useEffect(()=>{
    if(resetPasswordData?.data?.payload?.email){
      toast.success("Password reset successfully!")
      reset();
      setConfirmPassword("");
      setPassword("");
      setTimeout(()=>{
        navigate("/login")
      },3000)
    }else if(resetPasswordError){
      toast.error("Something went wrong!");
      reset();
    }
  },[resetPasswordData, resetPasswordError])
  
 

  function passwordValidation():boolean{
    if((!password || !password?.trim())){
      setPassError("Please enter password!");
      return false;
    }
    else if(password?.length < 8){
      setPassError("Password should be min 8 characters");
      return false;
    }
    else if(password && confirmPassword && password!==confirmPassword){
      setPassError("Passwords does not match");
      setConfirmPassError("Passwords does not match")
      return false;
    }
    setPassError("")
    return true;
  }

  function confirmPasswordValidation():boolean{
    if((!confirmPassword || !confirmPassword?.trim())){
      setConfirmPassError("Please enter password!");
      return false;
    }
    else if(confirmPassword?.length < 8){
      setConfirmPassError("Password should be min 8 characters");
      return false;
    }
    else if(password && confirmPassword && password!==confirmPassword){
      setPassError("Passwords does not match");
      setConfirmPassError("Passwords does not match")
      return false;
    }
    setConfirmPassError("")
    return true;
  }

  

  const onSubmit = (e: any) => {
    e.preventDefault();

    if(passwordValidation() && confirmPasswordValidation()){
      resetPassword({
        id:params.id,
        "email": searchEmail,
        "newPassword": password,
      })
      localStorage.setItem('email', email);
    }
    
    
  }
  if(!searchEmail) return <NotFound/>
  return (
    <>
      <section className="authWrapper">
        <div className="authMain flex-fill d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="authWrap">
                  <div className="mb-4 pb-3">
                    <h1>Change Password</h1>
                  </div>
                  <div className="formWrap">
                    <form onSubmit={onSubmit}>
                      <div className="mb-4">
                        <label className="mb-2 fw500 control-label">Email Address</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email"
                          value={searchEmail}
                          disabled={true}
                        />

                      </div>
                      <div className="mb-4">
                        <label className="mb-2 fw500 control-label">New Password</label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Enter password"
                          value={password}
                          onChange={(e) =>{
                            setPassword(e.target.value?.trim())
                          }}
                        />
                          {passError && <p className='text-danger'>{passError}</p>}
                      </div>

                       <div className="mb-4 pb-4">
                        <label className="mb-2 fw500 control-label">Confirm New Password</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter password"
                          value={confirmPassword}
                          onChange={(e) =>{
                            setConfirmPassword(e.target.value?.trim())
                          }}
                        />
                          {confirmPassError && <p className='text-danger'>{confirmPassError}</p>}
                      </div>
                     
                      <div className="text-center pt-3">
                        <button type="submit" className="button button-primary button-rounded button-block fw700">Submit</button>
                      </div>
                      

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Toaster position='bottom-center' />
    </>
  );
};

export default PasswordReset;
