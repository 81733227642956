import React from "react";
import { Routes, Route, BrowserRouter, Navigate, Router } from 'react-router-dom';
import PublicLayout from "../layouts/PublicLayout";
import { useAppSelector } from "../app/hooks";
import { isUserLoggedIn } from "../features/auth/authSlice";
import Courses from "../features/courses";
import Accommodations from "../features/accommodations";
import AccommodationDetail from "../features/accommodations/components/accommodation-detail/accommodation-detail";
import Users from "../features/users";
import MerchantSignup from "../features/merchant-signups";
import TeeOnSignup from "../features/tee-on-signups";
import Login from "../features/auth/pages/login";
import Bookings, { ThirdPartyBookings, UserBookings } from "../features/bookings";
import AccomdationsDetail from "../features/accommodations/components/accommodation-detail/accommodation-detail"
import BookingDetails from "../features/bookings/components/booking-details/booking-details";
import CourseDetail from "../features/courses/components/course-detail/course-detail";
import Transactions from "../features/transactions";
import Payouts from "../features/payouts";
import PayoutDetail from "../features/payoutsDetail";
import Dashboard from "../features/dashboard/components/dashboard";
import CourseSyncInfo from "../features/course-sync-info";
import Taxes from "../features/taxes";
import CourseOnBoarding from "../features/course-onboarding/courseOnBoarding";
import Todos from "../features/todos";
import CourseDetailWrapper, { CourseRedirect } from "../features/courses/components/course-detail-wrapper/course-detail-wrapper";
import CourseReviewWrapper from "../features/courses/components/course-review/course-review";
import CourseBookingsWrapper from "../features/courses/components/course-bookings-wrapper/course-booking-wrapper";
import { CourseBookingsBreadcrumbNav } from "../features/courses/components/course-bookings/course-bookings";
import TargetCoursesList from "../features/targetcourses/components/target-courses-list/target-courses-list";
import TargetCourses from "../features/targetcourses";
import TragetCoursesForm from "../features/targetcourses/components/target-courses-form/target-courses-form";
import TargetCoursesNotes from "../features/targetcourses/target-courses-notes";
import PromoSettings from "../features/promosettings";
import AdminPrivateLayout from "../layouts/AdminPrivateLayout";
import TournamentsBookings from "../features/bookings/components/tournament-bookings";
import SalesPrivateLayout from "../layouts/SalesPrivateLayout";
import QRCodeScans from "../features/qrcodescans";
import SalesTarget from "../features/salestarget";
import SalesTargetNotes from "../features/salestarget/components";
import DefaultProducts from "../features/defaultproducts";
import DefaultProductsForm from "../features/defaultproducts/components/default-products-form/default-products-form";
import Tournaments from "../features/tournaments";
import TournamentDetails from "../features/tournaments/tournaments-detail/tournaments-detail";
import TournamentGolfersWrapper from "../features/tournaments/tournament-golfers/tournament-golfers";
import TournamentFlightsWrapper from "../features/tournaments/tournament-flights/tournament-flights";
import TournamentDetailWrapper, { TournamentRedirect } from "../features/tournaments/tournament-detail-wrapper/tournament-detail-wrapper";
import TournamentWinnersWrapper from "../features/tournaments/tournament-winners/tournament-winners";
import TournamentCourses from "../features/tournament-courses";
import TournamentCourseDetailWrapper, { TournamentDetailRedirect } from "../features/tournament-courses/tournament-detail-wrapper/tournament-detail-wrapper";
import TournamentCourseDetailsWrapper from "../features/tournament-courses/tournment-courses-detail/tournament-courses-detail";
import TournamentBookingsDetails from "../features/bookings/components/tournament-bookings/tournament-bookings-detail/tournament-bookings-detail";
import Blog from "../features/blog";
import News from "../features/news";
import BlogCommentsWrapper from "../features/blog/components/comments-by-blog/comments-by-blog";
import Bankaccounts from "../features/bankaccounts";
import PackageBookings from "../features/bookings/components/package-bookings";
import PackageBookingsDetail from "../features/bookings/components/package-bookings/package-bookings-detail/package-bookings-detail";
import BookingQuery from "../features/booking-query";
import PasswordReset from "../features/auth/pages/PasswordReset";
import Translations from "../features/translations";

const Navigation = () => {
  const isUser = useAppSelector(isUserLoggedIn);
  return (
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<AdminPrivateLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/todos" element={<Todos />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/course/:id" element={<CourseDetailWrapper />}>
            <Route path="" element={<CourseRedirect />} />
            <Route path="detail" element={<CourseDetail />} />
            <Route path="bookings" element={<CourseBookingsWrapper />} />
            <Route path="reviews" element={<CourseReviewWrapper />} />
          </Route>
          <Route path="/course/:id/bookings/detail" element={<BookingDetails children={<CourseBookingsBreadcrumbNav />} />} />
          <Route path="/course/course-sync/:id" element={<CourseSyncInfo />} />
          <Route path="/course/course-onboarding/:id" element={<CourseOnBoarding />} />
          <Route path="/accommodations" element={<Accommodations />} />
          <Route path="/translations" element={<Translations />} />
          <Route path="/accommodations/detail" element={<AccomdationsDetail />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/bankaccounts/:id" element={<Bankaccounts />} />
          <Route path="/merchant-signups" element={<MerchantSignup />} />
          <Route path="/teeon-signups" element={<TeeOnSignup />} />
          <Route path="/taxes" element={<Taxes />} />
          <Route path="/tournamentcourses" element={<TournamentCourses/>}/>
          <Route path="/tournamentcourses/:id" element={<TournamentCourseDetailWrapper />}>
            <Route path="" element={<TournamentDetailRedirect />} />
            <Route path="tournaments" element={<TournamentCourseDetailsWrapper />} />
          </Route>
          <Route path="/bookings" element={<Bookings />} />
          <Route path="/bookings">
            <Route path="user-bookings" element={<UserBookings />} />
            <Route path="third-party" element={<ThirdPartyBookings />} />
          </Route>

          <Route path="/bookings/user-bookings/detail" element={<BookingDetails />} />
          <Route path="/bookings/third-party/detail" element={<BookingDetails />} />
          <Route path="/bookings/detail" element={<BookingDetails />} />
          <Route path="/bookings/tournaments" element={<TournamentsBookings/>}/>
          <Route path="/bookings/tournaments/detail" element={<TournamentBookingsDetails/>}/>
          <Route path="/bookings/packages" element={<PackageBookings/>}/>
          <Route path="/bookings/packages/detail" element={<PackageBookingsDetail/>}/>
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/payouts" element={<Payouts />} />
          <Route path="/payout/detail" element={<PayoutDetail />} />
          <Route path="/promosettings" element={<PromoSettings />} />
          <Route path="/qrcodescans" element={<QRCodeScans/>}/>
          <Route path="/salestarget" element={<SalesTarget/>}/>
          <Route path="/salestarget/notes/:id" element={<SalesTargetNotes/>}/>
          <Route path="/products" element={<DefaultProducts/>}/>
          <Route path="/bookingqueries" element={<BookingQuery/>}/>
          <Route path="/products/add" element={<DefaultProductsForm mode="add"/>}/>
          <Route path="/products/edit/:id" element={<DefaultProductsForm mode="edit"/>}/>
          <Route path="/tournaments" element={<Tournaments/>}/>
          <Route path="/tournaments/:id" element={<TournamentDetailWrapper />}>
            <Route path="" element={<TournamentRedirect />} />
            <Route path="detail" element={<TournamentDetails />} />
            <Route path="flights" element={<TournamentFlightsWrapper />} />
            <Route path="golfers" element={<TournamentGolfersWrapper />} />
            <Route path="winners" element={<TournamentWinnersWrapper />} />
          </Route>
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blogs/comments/:id" element={<BlogCommentsWrapper />} />
        
          <Route path="/news" element={<News/>} />
        </Route>

        <Route path="/" element={<SalesPrivateLayout />}>
          <Route path="/targetcourses" element={<TargetCourses />}></Route>
          <Route path="/targetcourses/add" element={<TragetCoursesForm />}></Route>
          <Route path="/notes/:id" element={<TargetCoursesNotes />}></Route>
        </Route>

        <Route element={<PublicLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/password-reset/:id" element={<PasswordReset />} />

        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
