import React, { useEffect, useState } from 'react';
import { useMutation } from "react-query";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
import VerifyOtp from "./VerifyOtp";
import { useNavigate } from 'react-router-dom';
const login = (payload: any) => {
  return axios.post(`${process.env.REACT_APP_USER_API_BASEURL}/users`, payload);
}

const forgotPassword = (payload: any) => {
  return axios.post(`${process.env.REACT_APP_USER_API_BASEURL}/users/forgot-password`, payload);
}

export const useLogin = () => {
  return useMutation(login)
}

export const useForgotPassword = () => {
  return useMutation(forgotPassword)
}


const LoginPage = () => {
  const { mutate: login, data, error } = useLogin();
  const { mutate: forgotPassword, data:forgotPasswordData, error:forgotPasswordError, reset:resetForgotPassowrd } = useForgotPassword();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [inputPassType, setInputPasswordType] = useState("password");
  const [signInOtp, setSignInOtp] = useState(false);
  const [formError, setFormErrors] = useState({
    email:"", password:""
  })
  const navigate = useNavigate();

  function togglePasswordType(){
    let newInputType;
    if(inputPassType==="password"){
      newInputType = "text"
    }else{
      newInputType = "password";
    }
    setInputPasswordType(newInputType);
  }

  function validateEmail(email:string) {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  function emailValidation(){
    if(!email?.trim()){
      setFormErrors({...formError, email:"Please enter email!"});
      return false;
    }else if(!validateEmail(email)){
      setFormErrors({...formError, email:"Please enter valid email!"});
      return false;
    }
    setFormErrors({...formError, email:""})
    return true;
  }

  function handleForgotPassword(){
    let emailValid = emailValidation();
    if(emailValid){
      forgotPassword({
        email,
        role:"admin"
      })
    }
  }

  useEffect(()=>{
    console.log(forgotPasswordData)
    if(forgotPasswordData?.data?.payload?.email){
      toast.success("Password reset link sent successfully!")
      resetForgotPassowrd();
      setEmail("");
      setPassword("");
    }else if(forgotPasswordError){
      toast.error("Something went wrong!");
      resetForgotPassowrd();
    }
  },[forgotPasswordData, forgotPasswordError])
  
  useEffect(() => {
    if (data) {
      const accessToken = data?.headers?.accesstoken!;
      const userRole=data?.data?.payload?.role;
      if(userRole){
        localStorage.setItem("role",userRole)
      }
      if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        if (userRole === "admin") {
          navigate("/dashboard", {
            state:
            {
              email: data?.data?.payload?.email,
              role: data?.data?.payload?.role,
            }
          });
        } else {
            navigate("/targetcourses", {
              state: {
                email: data?.data?.payload?.email,
                role: data?.data?.payload?.role,
              }
            });
        }
      }else{
        setShowVerifyOtp(true)
      }
      
    }
  }, [data]);

  function passwordValidation():boolean{
    if((!password || !password?.trim())){
      setFormErrors({...formError, password:"Please enter password!"});
      return false;
    }
    else if(password?.length < 8){
      setFormErrors({...formError, password:"Password should be min 8 characters"});
      return false;
    }
    setFormErrors({...formError, password:""})
    return true;
  }

  useEffect(() => {
    if (error) {
      toast.error('Invalid credentials')
    }
  }, [error]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    if(!email || !email?.trim()){
      toast.error('Please enter valid email');
    }else{
      let validation = signInOtp? true : passwordValidation();
      if(validation===true){
        login({
          "email": email,
          "password": signInOtp ? undefined : password,
          "role":"admin"
        })
        localStorage.setItem('email', email);
      }
    }
    
  }
  if (showVerifyOtp) {
    return (
      <VerifyOtp resendOtp={login} setShowVerifyOtp={setShowVerifyOtp} email={email} />
    )
  }
  return (
    <>
      <section className="authWrapper">
        <div className="authMain flex-fill d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="authWrap">
                  <div className="mb-4 pb-3">
                    <h1>Login</h1>
                  </div>
                  <div className="formWrap">
                    <form onSubmit={onSubmit}>
                      <div className="mb-4">
                        <label className="mb-2 fw500 control-label">Email Address</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                          {formError?.email && <p className='text-danger'>{formError.email}</p>}

                      </div>
                     {!signInOtp && <div className="mb-4 pb-4">
                        <label className="mb-2 fw500 control-label">Password</label>
                        <input
                          type={inputPassType}
                          className="form-control"
                          placeholder="Enter password"
                          value={password}
                          onChange={(e) =>{
                            setPassword(e.target.value?.trim())
                          }}
                        />
                        {/* <span 
                          onClick={togglePasswordType}
                          style={{
                            position:"absolute",
                            top:formError?.password ? "51.8%":"53.5%",
                            left: "68%",
                            cursor:"pointer"
                          }}
                          ><i className="fas fa-eye"></i></span> */}
                          {formError?.password && <p className='text-danger'>{formError.password}</p>}

                          <a className='m-2 fw400' style={{float:"right"}}
                            onClick={handleForgotPassword}>
                              Forgot Password
                            </a>
                      </div>}
                     
                      <div className="text-center pt-3">
                        <button type="submit" className="button button-primary button-rounded button-block fw700">Sign In</button>
                      </div>
                      <div className="text-center pt-3">
                        <a className='fw400 mt-3 ' onClick={()=>setSignInOtp(!signInOtp)}>{(signInOtp)?'Sign in with password':'Sign in with OTP'}</a>
                        
                      </div>
                      

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Toaster position='bottom-center' />
    </>
  );
};

export default LoginPage;
