import './translations-list.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';

const TranslationsList = ({
  data,
  handleDownloadData
}: any) => {
  return (
    <>
      <div className='accommodationsListWrapper'>
        <div className='tableLayout'>
          <div className='table-responsive'>
            <table className='table mb-0'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <div className='d-flex align-items-center'>
                      <span>Portal Name</span>
                    </div>
                  </th>
                  <th>Updated On</th>
                  <th>Updated By</th>
                  <th className='width-50'>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((e: any, j: number) => {
                  // const {class: cssClass, label} = getStatusDetail(e.course?.status);

                  return (
                    <>
                      <tr key={'key_' + j}>
                        <td>{(j + 1)}</td>
                        <td>
                          {e?.portal === "mainsite" ? "Main Site" : 'n/a'}
                        </td>
                        <td>{dayjs(e?.updatedAt).format('DD MMM YYYY hh:mm a')}</td>
                        <td className='text-capitalize'>{ e?.user?.firstname ? `${e?.user?.firstname} ${e?.user?.lastname}` : "Integolf Admin"}</td>
                        <td className="text-center">
                          <button
                              className="button button-primary mt-3 button-rounded button-sm fontsize-14 fw500 px-3 mb-3 statusbutton d-flex align-items-center justify-content-center"
                            onClick={() => handleDownloadData({ portalname: e?.portal })}
                          >
                            <i className="fa-solid fa-download"></i>
                          </button>
                        </td>

                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default TranslationsList;