import React, { FC } from "react";
import "./aside-navigation.scss";
import { NavLink, useMatch, useNavigate } from "react-router-dom";
import axios from "axios";
import { useMutation } from "react-query";

interface AsideNavigationProps {}

const AsideNavigation: FC<AsideNavigationProps> = () => {
  const navigate = useNavigate();
  const inBookings = useMatch('/bookings/*');
  const role=localStorage.getItem("role");

  const verifyOtp = () => {
  const accessToken = localStorage.getItem('accessToken');

    return axios.put(`${process.env.REACT_APP_USER_API_BASEURL}/users/logout`,{
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
  }
  const useVerifyOtp = () => {
    return useMutation(verifyOtp)
  }
  const { mutate: logout, error } = useVerifyOtp();

  return (
    <aside className="sideMenuWrapper d-flex flex-column">
      <div className="sideMenuWrapper__logo">
        <a href={void 0}>
          <img
            src="./assets/images/brand/integolf.svg"
            alt=""
            className="img-fluid"
          />
        </a>
      </div>
      <div className="sideMenuWrapper__navigation flex-fill">
        <h5 className="mb-3 sideMenuWrapper__title">Main Menu</h5>
        {role==="admin"? 
        <ul>
        
        <li>
          <NavLink to={"/dashboard"} className="nav-item">
            <em className="icon-pie-chart me-2"></em> Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink to={"/todos"} className="nav-item">
            <em className="fa-solid fa-list-check me-2 me-2"></em> Todos
          </NavLink>
        </li>

        <li>
          <NavLink to={"/users"} className="nav-item">
            <em className="icon-users-filled me-2"></em> Users
          </NavLink>
        </li>

        <li>
          <NavLink to={"/salestarget"} className="nav-item">
            <em className="icon-users-filled me-2"></em> Sales Target
          </NavLink>
        </li> 

        <li>
            <NavLink to={"/courses"} className="nav-item">
              <em className="icon-golf-filled me-2"></em> Courses
            </NavLink>
        </li>
        <li>
            <NavLink to={"/tournamentcourses"} className="nav-item">
              <em className="icon-golf-filled me-2"></em>Tournament Courses
            </NavLink>
        </li>
        <li>
            <NavLink to={"/accommodations"} className="nav-item">
              <em className="fa-solid fa-hotel me-2"></em> Accommodations
            </NavLink>
        </li>
        <li>
          <NavLink to={"/tournaments"} className="nav-item">
            <em className="icon-wallet-filled me-2"></em> Tournaments
          </NavLink>
        </li>
        <li>
          <NavLink to={"/bookings"} className="nav-item">
            <em className="icon-calendar-filled me-2"></em> Bookings
          </NavLink>
          
        </li>
        {<div className={`bookings-subitems ${inBookings ? '' : 'bookings-subitems-hide'}`}>
          <li>
            <NavLink to={"/bookings/user-bookings"} className="nav-item">
              <em className="me-2"></em>Discounted Bookings
            </NavLink>
          </li>
          <li>
            <NavLink to={"/bookings/third-party"} className="nav-item">
              <em className="me-2"></em>Tourism Bookings
            </NavLink>
          </li>
          <li>
            <NavLink to={"/bookings/tournaments"} className="nav-item">
              <em className="me-2"></em>Tournament Bookings
            </NavLink>
          </li>
          <li>
            <NavLink to={"/bookings/packages"} className="nav-item">
              <em className="me-2"></em>Golf Package Bookings
            </NavLink>
          </li>
        </div>}
        <li>

          <NavLink to={"/transactions"} className="nav-item">
            <em className="icon-wallet-filled me-2"></em> Transactions
          </NavLink>
        </li>
        <li>
          <NavLink to={"/products"} className="nav-item">
            <em className="fa-solid fa-cart-plus"></em> &nbsp;&nbsp; Products
          </NavLink>
        </li>
        <li>
          <NavLink to={"/payouts"} className="nav-item">
            <em className="icon-wallet-filled me-2"></em> Payouts
          </NavLink>
        </li>
        <li>
          <NavLink to={"/merchant-signups"} className="nav-item">
            <em className="icon-users-filled me-2"></em> Merchant Signups
          </NavLink>
        </li>
        <li>
          <NavLink to={"/teeon-signups"} className="nav-item">
            <em className="icon-users-filled me-2"></em> Tee-On Signups
          </NavLink>
        </li>
        <li>
          <NavLink to={"/taxes"} className="nav-item">
            <em className="fas fa-hand-holding-usd"></em>&nbsp;&nbsp;Taxes
          </NavLink>
        </li>
        <li>
          <NavLink to={"/translations"} className="nav-item">
            <em className="fa-solid fa-language"></em>&nbsp;&nbsp;Translations
          </NavLink>
        </li>
        <li>
          <NavLink to={"/bookingqueries"} className="nav-item">
            <em className="fa-solid fa-clipboard-question me-2"></em> Booking Queries
          </NavLink>
        </li>
        <li>
          <NavLink to={"/qrcodescans"} className="nav-item">
            <em className="fa-solid fa-qrcode me-2"></em> QR Scans
          </NavLink>
        </li>

        <li>
          <NavLink to={"/promosettings"} className="nav-item">
            <em className="fa-solid fa-gear me-2"></em> Settings
          </NavLink>
        </li>
        <li>
          <NavLink to={"/blogs"} className="nav-item">
            <em className="fa-solid fa-file-circle-plus me-2"></em> Blogs
          </NavLink>
        </li>
        <li>
          <NavLink to={"/news"} className="nav-item">
            <em className="fa-solid fa-newspaper me-2"></em> News
          </NavLink>
        </li>
        
          {/* <li>
            <NavLink to={"/dashboard"} className="nav-item">
              <em className="icon-pie-chart me-2"></em> Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink to={"/bookings"} className="nav-item">
              <em className="icon-calendar-filled me-2"></em> Bookings
            </NavLink>
          </li>
          <li>
            <NavLink to={"/transactions"} className="nav-item">
              <em className="icon-wallet-filled me-2"></em> Transactions
            </NavLink>
          </li>
          <li>
            <NavLink to={"/"} className="nav-item">
              <em className="icon-users-filled me-2"></em> Admin
            </NavLink>
          </li> */}
        </ul>
        : <>
        <ul>
        <li>
          <NavLink to={"/targetcourses"} className="nav-item">
            <em className="icon-users-filled me-2"></em> Target Courses
          </NavLink>
        </li>
        </ul>
        </>}
      </div>
      <div className="sideMenuWrapper__navigation">
        
        <ul>
          {/* <li>
            <NavLink to={"/dashboard"} className="nav-item">
              <em className="icon-gear-filled me-2"></em> Settings
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink to={"/"} className="nav-item">
              <em className="icon-help-filled me-2"></em> Help
            </NavLink>
          </li> */}
          <li>
            <NavLink onClick={(e)=>{
              e.preventDefault();
              localStorage.clear();
              logout();
              navigate('/login');
            }} to={"/"} className="nav-item">
              <em className="fa-solid fa-arrow-right-from-bracket me-2"></em> Logout
            </NavLink>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default AsideNavigation;