import  { FC, useEffect, useState } from 'react';
import './translations.scss';
import IntegolfLoader from '../../components/integolf-loader/integolf-loader';
import IntegolfDataNotFound from "../../components/integolf-data-not-found/integolf-data-not-found";
import { usePagination } from '../../contexts/PaginationContext';
import axios from 'axios';
import { useMutation } from 'react-query';
import TranslationsList from './components/translations-list/translations-list';
import FileUploadModal from "./components/modals/uploadTranslationFile";
import { toast } from 'react-toastify';

interface TranslationsProps {}

const Translations: FC<TranslationsProps> = () => {
	const [portalsMenu,setPortalMenu]=useState([])
	const [showFileModal,setShowFileModal]=useState(false);
	const handleTranslationDataDownload = async ({ 
		portalname
	 }: any) => {
		const accessToken :any= localStorage.getItem('accessToken');
		const response = await axios.post(
			`${process.env.REACT_APP_ADMIN_API_BASEURL}/downloadTranslation`,
			{ portalname }, // Send portalname directly in the request body
			{
				headers: {
					'Accesstoken': accessToken,
					'Content-Type': 'application/json',
				},
			}
		);
		return response.data;
	}

	const getTranslations = async ({ 
		accessToken,
		createdAt,
		searchTerm
	 }: any) => {
		const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/getTranslations`, {
			params: {	
			},
			headers: {
				'Accesstoken': accessToken,
			}
		});
		return response.data;
	}

	const handleUploadTranslationFile=async({	
		formData	
	}:any)=>{
		const accessToken :any= localStorage.getItem('accessToken');
		const response = await axios.post(
			`${process.env.REACT_APP_ADMIN_API_BASEURL}/uploadTranslationFile`,
			formData, // Send portalname directly in the request body
			{
				headers: {
					'Accesstoken': accessToken,
					'Content-Type': 'application/json',
				},
			}
		);
		return response.data; 
	}

	const accessToken = localStorage.getItem('accessToken');
	const { isLoading, error, data, mutate: getTranslationsData } = useMutation('translations', () => getTranslations({
		accessToken: accessToken!
	}));

	const {error:uploadError,data:uploadData,mutate:handleFileUpload,isSuccess:successFileUpload}=  useMutation(({ formData}: { formData:FormData}) => handleUploadTranslationFile({ formData}));
	const {error:downloadError,data:downloadData,mutate:handleDownloadData,isSuccess}=  useMutation(({ portalname }: { portalname: string }) => handleTranslationDataDownload({ portalname }));

	useEffect(()=>{
		if(downloadData&&isSuccess){
			const blob = new Blob([JSON.stringify(downloadData?.response, null, 2)], { type: 'application/json' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'translation.json';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
		}
	},[isSuccess,downloadData])

	useEffect(()=>{
      if(data?.response && data?.response?.length > 0){
		const portals=data?.response?.map((d:any)=>{
            return d?.portal
		});
		setPortalMenu(portals)
	  }
	},[data])

	const uploadTranslation=()=>{
		setShowFileModal(true);
	}

	useEffect(() => {
		getTranslationsData();
	}, [
		getTranslationsData,
		successFileUpload,
		uploadError
	]);

	const fileUploadHandler = (file: any,portal:string) => {
		const formData = new FormData(); // Create a new FormData object
	
		if (file && file.length > 0) {  
			const fileType = file[0].type;
			if (fileType !== 'application/json') {
				toast.error('Please upload a JSON file.');
				return;
			}	
			formData.append('file', file[0]); 
			formData.append('portalname', portal);
	
			handleFileUpload({ formData });
		}
	  }

	  useEffect(()=>{
		if(successFileUpload){
			toast.success("Changes updated successfully.")
		}
	  },[successFileUpload])

	return (
		<>
			<div className='accommodationsWrapper'>
				<h1 className='mb-3 pageTitle'>Translations</h1>
				<div className='row'>
					<div className='col-4'>
					</div>
					<div className='col-8 mb-2 text-align-right'>
						<button
						type='button'
						className='button button-sm button-rounded button-white button-filter fontsize-14 px-3'
						onClick={()=>uploadTranslation()}
						>
							<em className='fa-solid fa-upload me-2'></em> Upload File
						</button>
					</div>
				</div>
				<div className='container-fluid p-0'>
					{data?.response?.count === 0 && <IntegolfDataNotFound message={"No Translations found"}/>}
					{data?.response ? <TranslationsList data={data?.response} handleDownloadData={handleDownloadData}/>
						:
						<IntegolfLoader show />}
				</div>

				{showFileModal && <FileUploadModal
				   acceptType='.json'
				   fileUploadHandler={fileUploadHandler}
				   enableDragAndDrop={false}
				   show={showFileModal}
				   setShow={setShowFileModal}
                   uploadMultiple={false}
				   AcceptedFilesPlaceholder={"Upload translation file"}
                   portalMenu={portalsMenu}
				/>}
			</div>
		</>
	);
};

export default Translations;


